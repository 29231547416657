<!--  -->
<template>
  <div class="material-cont">
    <div class="header">
      <div class="available-limit">
        <label>可用额度(元)</label>
        <div class="amount">{{ limitAmount }}</div>
      </div>
      <img class="material-media" src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230628103557938.png" />
      <div class="nav-list">
        <div class="nav-item" @click="routeJump('/MaterialLimitDetail?bid=' + brandId)">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230628102545234.png" />
          <div>查看额度明细</div>
        </div>
        <div class="nav-item" @click="pageJump(baseHost + '/7999/html/supplies/orderlist.html')">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230628102635324.png" />
          <div>物料订单管理</div>
        </div>
      </div>
    </div>
    <div class="brand-cont">
      <label>品类：</label>
      <div class="brand-item" :class="{ 'active': brandId === 3 }" @click="brandChange(3)">美妆</div>
      <div class="brand-item" :class="{ 'active': brandId === 4 }" @click="brandChange(4)">大健康</div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="good-item" v-for="(item, index) in datalist" :key="index">
        <van-image width="125" height="125" :src="item.Url" fit="cover" />
        <div class="media-body">
          <div class="pro-name ellipsis2">{{ item.Name }}</div>
          <div class="spec" v-if="item.Specifications">{{ item.Specifications }}</div>
          <div class="limit-msg">{{ item.message }}</div>
          <div class="price">￥<span>{{ item.Price }}</span></div>
          <div class="cus-stepper">
            <van-stepper v-model="item.GoodsCount" :min="0" :max="item.Num" :step="item.BuyNum" theme="round"
              button-size="24" disable-input :name="item.ID" :class="{ 'hide-minus-btn': item.GoodsCount <= 0 }"
              @change="onStepperChange" />
          </div>
        </div>
      </div>
    </van-list>
    <div class="footer">
      <a :href="baseHost + '/7999/html/supplies/goshoppingcart.html'" class="car-but">
        <div class="box">
          <span class="icon-030"></span>
          <span class="mui-badge">{{ cartNum >= 100 ? '99+' : cartNum }}</span>
        </div>
      </a>
      <div class="car-text">
        <div class="car-money">总价<span>￥{{ amount }}</span></div>
        <span data-lan="TotalValue">有蜜豆</span>:{{ miCount }},可抵扣:￥{{ deductionAmount }}
      </div>
      <van-button type="primary" round color="#ff98a0" @click="onSettlement">去结算</van-button>
    </div>
  </div>
</template>

<script>
import { accMul, Subtr } from "../../utils/handle"
export default {
  name: '',
  data() {
    return {
      brandId: 3,
      brandList: [{ brandId: 3, text: '美妆' }, { brandId: 4, text: '大健康' }],
      loading: false,
      finished: false,
      pageIndex: 0,
      pageSize: 10,
      datalist: [],
      miCount: 0,//蜜豆数量
      amount: 0,//总价
      cartNum: 0,//购物车数量
      deductionAmount: 0, //可抵扣金额
      baseHost: this.baseHost,
      limitAmount: 0,//可用额度
      level: 0 //身份等级
    }
  },
  created() {
    this.getPayLimit();
    this.getMiAccount();
    this.getUserIdentity()
  },
  methods: {
    brandChange(bid) {
      if (this.brandId === bid) return;
      this.brandId = bid;
      this.finished = false;
      this.datalist = [];
      this.cartNum = 0;
      this.amount = 0;
      this.deductionAmount = 0;
    },
    onLoad() {
      this.pageIndex++;
      this.post("/MaterialZone/Supplies/ProductList", {
        BrandID: this.brandId,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize
      }, true, 2).then(res => {
        this.loading = false;
        if (res.code === 1) {
          let list = res.response;
          this.initCartInfo(list);
          this.datalist = this.datalist.concat(list);
          this.calcAccount();
        }
        this.finished = true;
      }).catch(() => {
        this.loading = false;
        this.finished = true;
      })
    },
    //初始化购物车数量
    initCartInfo(list) {
      let listStr = localStorage.getItem("list") || "";
      let cartList = listStr ? JSON.parse(listStr) : [];
      cartList.forEach(item => {
        if (item.bid == this.brandId) {
          item.datas.forEach(it => {
            let good = list.find(g => g.ID == it.gid);
            if (good) good.GoodsCount = it.BuyNum;
          })
        }
      })
    },
    //获取蜜豆账户信息
    getMiAccount() {
      this.get("/MiMall/HoneyShop/GetMiCount").then(res => {
        if (res.code === 1) {
          this.miCount = res.total;
        }
      })
    },
    //获取用户信息
    getUserIdentity() {
      this.get("/TeamAgent/AgentInfo/HomeIdentity_V4").then(res => {
        if (res.code === 1) { 
          this.level = res.response.level;
        }
      })
    },
    //获取购买额度
    getPayLimit() {
      this.get("/MaterialZone/Supplies/GetOrderLimit", {
        brandId: this.brandId
      }, 2).then(res => {
        if (res.code === 1) {
          const { buyAmount } = res.response;
          this.limitAmount = buyAmount;
        }
      })
    },
    //点击添加商品
    onStepperChange(e, detail) {
      let item = this.datalist.find(it => it.ID === detail.name);
      //修改购物车数据 ---暂时不做服务器交互。做本地缓存
      // if (item.GoodsCount <= 0) {
      //   this.deleteShopCartByIds(item.CartId)
      // } else { //删除购物车商品
      //   this.calcAccount();
      // }
      let good = {
        BrandId: item.BrandId,
        BuyNum: item.GoodsCount,
        IsLimit: item.IsLimit,
        Name: item.Name,
        Price: item.Price,
        Url: item.Url,
        gid: item.ID,
        max_num: item.Num,
        min_num: item.BuyNum,
        state: 0
      }
      this.changeLocalShopCart(good);
      this.calcAccount();
    },
    //更新本地缓存购物车数据
    changeLocalShopCart(data) {
      const brandId = this.brandId;
      let listStr = localStorage.getItem("list") || "";
      let list = listStr ? JSON.parse(listStr) : []
      let item = list.find(it => it.bid == brandId);
      if (item) {
        let it = item.datas.find(good => good.gid === data.gid);
        if (it) {
          it.BuyNum = data.BuyNum;
        } else {
          item.datas.push(data);
        }
      } else {
        list.push({ bid: brandId, bname: (brandId === 3 ? '美妆' : '大健康'), datas: [{ ...data }] })
      }
      localStorage.setItem("list", JSON.stringify(list))
    },
    //加入购物车
    changeShopCart() {
      let cartList = [];
      this.datalist.forEach(item => {
        if (item.GoodsCount > 0) {
          cartList.push({ productId: item.ID, count: item.GoodsCount, state: 1 })
        }
      })
      this.post("/MaterialZone/Supplies/AddSuppliesCarts", {
        brandId: this.brandId,
        data: JSON.stringify(cartList),
      }, true, 2).then((res) => {
        if (res.code === 1) {

        }
      })
    },
    //从购物车删除数据
    deleteShopCartByIds(ids) {
      this.$toast.loading({ message: '', duration: 0, overlay: false });
      this.post("/MaterialZone/Supplies/DelSuppliesCarts", {
        ids,
      }, true, 2).then((res) => {
        this.$toast.clear();
        if (res.code === 1) {
          this.calcAccount();
        }
      }).catch(() => {
        this.$toast.clear();
      })
    },
    //计算账户信息
    calcAccount() {
      let totalAmount = 0, totalNum = 0;;
      this.datalist.forEach(item => {
        if (item.GoodsCount > 0) {
          totalNum += item.GoodsCount;
          totalAmount = Subtr(totalAmount, - accMul(item.GoodsCount, item.Price));
        }
      });
      this.amount = totalAmount;
      this.cartNum = totalNum;
      let canUseMi = parseInt(this.miCount / 100);
      this.deductionAmount = canUseMi > totalAmount ? totalAmount : canUseMi;
    },
    //页面跳转
    pageJump(path) {
      window.location.href = path;
    },
    routeJump(path) {
      this.$router.push(path)
    },
    //去结算
    onSettlement() {
      if (this.cartNum > 0) {
        if (this.level < 3) { 
          this.$dialog.alert({
            title: "提示",
            message: "非常抱歉，\n目前物料商品仅供旗舰店店主购买",
          })
          return;
        }
        if (this.limitAmount <= 0 || this.amount > this.limitAmount) {
          this.$dialog.confirm({
            title: "提示",
            message: "您的额度不足，请前往采购订货增加额度",
            confirmButtonText: "前往采购订货"
          }).then(() => {
            window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html?"
          })
          return;
        }
        let listStr = localStorage.getItem("list") || "";
        let list = listStr ? JSON.parse(listStr) : [];
        list.forEach(item => {
          let state = item.bid == this.brandId ? 1 : 0;
          item.datas.forEach(it => it.state = state);
        })
        localStorage.setItem("list", JSON.stringify(list));
        window.location.href = this.baseHost + "/7999/html/supplies/ordercommit.html?"
      } else {
        this.$toast({ message: "请选择要结算的商品", icon: 'warning-o' })
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.material-cont {
  .header {
    color: #fff;
    padding-top: 20px;
    position: relative;
    background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230628102907153.png") no-repeat;
    background-size: 100% 100%;

    .available-limit {
      padding: 0 16px;
      text-align: left;

      label {
        font-size: 10px;
      }

      .amount {
        font-size: 32px;
        padding-top: 5px;
      }
    }

    .material-media {
      width: 162px;
      position: absolute;
      top: 0px;
      right: 16px;
    }

    .nav-list {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;

      .nav-item {
        width: 40%;
        padding: 11px 0;
        color: #fff;
        font-size: 12px;
        position: relative;

        img {
          width: 26px;
        }

      }

      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 17px);
        left: 50%;
        width: 1px;
        height: 34px;
        background-color: #fff;
      }
    }

  }

  .brand-cont {
    padding: 10px 16px;
    display: flex;
    color: #666;
    font-size: 14px;
    align-items: center;
    border-bottom: 1px solid #F8F8F8;

    label {
      padding-right: 5px;
    }

    .brand-item {
      color: #999;
      padding: 3px 16px;
      border-radius: 26px;
      border: 1px solid #EEEEEE;
      background-color: #EEEEEE;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        color: #FFB5BB;
        border-color: #FFB5BB;
        background-color: #fff;
      }
    }
  }

  .van-list {
    padding-bottom: 50px;

    .good-item {
      display: flex;
      position: relative;
      padding: 11px 15px;
      background: linear-gradient(180deg, #fff, #fff, #f5f5f5);
      background-image: -ms-radial-gradient(top bottom, circle cover, #fff, #fff, #f5f5f5);
      background: -webkit-linear-gradient(top, #fff, #fff, #f5f5f5);
      background: -o-linear-gradient(top, #fff, #fff, #f5f5f5);
      background: -moz-linear-gradient(top, #fff, #fff, #f5f5f5);

      .van-image {
        margin-right: 10px;
      }

      .media-body {
        flex: 1;
        color: #636363;
        font-size: 12px;
        text-align: left;

        .pro-name {
          font-size: 14px;
          font-weight: bold;
          padding-bottom: 6px;
        }

        .limit-msg {
          color: #b5b5b5;
          line-height: 24px;
        }

        .price {
          position: absolute;
          color: #ff98a0;
          bottom: 11px;
          font-size: 10px;

          span {
            font-size: 14px;
            font-weight: bold;
          }
        }

        .cus-stepper {
          position: absolute;
          right: 16px;
          bottom: 11px;

          :deep(.van-stepper__minus) {
            color: #aaa;
            border-color: #aaa;
          }

          :deep(.van-stepper__plus) {
            color: #fff;
            background-color: #ff98a0;
          }

          .hide-minus-btn {
            :deep(.van-stepper__minus) {
              display: none;
            }

            :deep(.van-stepper__input) {
              display: none;
            }
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    z-index: 1000;
    display: flex;
    padding: 8px 15px;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);

    .car-but {
      width: 50px;
      height: 50px;
      position: relative;
      border-radius: 50%;
      margin-right: 9px;
      text-align: center;
      margin-top: -16px;
      background-color: #ffb5bb;

      .icon-030 {
        color: #fff;
        font-size: 22px;
        line-height: 44px;

        &:before {
          content: "\e92c";
        }
      }

      .mui-badge {
        position: absolute;
        color: #ff98a0;
        top: 0;
        width: 30px;
        text-align: center;
        right: -10px;
        padding: 1px 2px;
        border-radius: 16px;
        border: 1px solid #ff98a0;
        background-color: #fff;
      }
    }

    .car-text {
      flex: 1;
      color: #999;
      font-size: 12px;

      .car-money {
        color: #666666;
        font-size: 14px;
        padding-bottom: 5px;

        span {
          color: #ff98a0;
        }
      }
    }

    .van-button {
      width: 95px;
      height: 33px;
    }
  }
}

:deep(.van-toast) {
  width: auto;
}
</style>